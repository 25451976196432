/* eslint-disable camelcase */
import React, { Component } from 'react';
import {
  FeatureFlagContainer,
  SetGlobalStyle,
  Loading,
  EmptyState,
  Spinner,
} from '@vezeeta/web-components';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { Flex } from 'grid-styled';
import EnterpriseLayout from '@vezeeta/enterprise-layout';
import { Provider, connect } from 'react-redux';
import { decodeJwtToken, Cookie } from '@vezeeta/web-utils';
import TagManager from 'react-gtm-module';
import { MixpanelConsumer } from 'react-mixpanel';
import '@vezeeta/web-components/lib/shared/Utils.scss';
import store from '@vezeeta/enterprise-store/lib';
import '../app/modules/modals//Modal.scss';
import { withTranslation } from '../app/withTranslation/withTranslation';


const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
};

TagManager.initialize(tagManagerArgs);

const AssistantsContainer = Loadable({
  loader: () => import('./assistants/AssistantsContainer'),
  loading: Loading,
});

class App extends Component {
  constructor(props) {
    super(props);

    SetGlobalStyle();
    let language = Cookie.get(Cookie.LANGUAGE);
    if (language === undefined) {
      language = 'en';
    }
    this.state = {
      language,
    };
  }

  getSteps = steps => [
    [
      {
        name: steps.settings,
        pathname: '/',
      },
    ],
  ];

  getFeatureFlags = () => ({
    enableManageBranches: window.enableManageBranches,
  });

  render() {
    const { language } = this.state;
    const { isUserLoaded, user, localization } = this.props;

    const breadcrumbSteps = [];
    if (localization) {
      const tabs = this.getSteps(localization.Steps);
      tabs.map(tab => breadcrumbSteps.push(tab));
    }
    const breadcrumbProps = {
      stepsArray: breadcrumbSteps,
      validationElementName: '#user-form',
    };

    let supportSingleLanguage = false;
    if (isUserLoaded) {
      // eslint-disable-next-line prefer-destructuring
      const countriesSupportSingleLanguage = window.countriesSupportSingleLanguage;
      supportSingleLanguage = countriesSupportSingleLanguage.includes(user.CountryId);
    }

    let isAssistant = false;
    // Get user token
    if (Cookie.get(Cookie.AUTH_TOKEN)) {
      const userToken = decodeJwtToken(Cookie.get(Cookie.AUTH_TOKEN)).payLoad;
      const userRoles = userToken.role;

      // Check if roles available in JWT token
      if (Array.isArray(userRoles)) {
        isAssistant = userRoles.some(role => role === 'Assistant') && breadcrumbSteps.length !== 0;
      }
    }

    return (
      <FeatureFlagContainer.Provider value={this.getFeatureFlags()}>
        <Provider store={store}>
          <Router>
            <EnterpriseLayout
              breadcrumbProps={breadcrumbProps}
              supportSingleLanguage={supportSingleLanguage}
              activeTab="Setting"
            >
              <input hidden type="text" id="user-form" value="true" readOnly />
              {breadcrumbSteps.length === 0 && (
                <Flex justifyContent="center" alignItems="center" py={10}>
                  <Spinner />
                </Flex>
              )}
              {isAssistant && (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  css={{
                    height: '500px',
                  }}
                >
                  <EmptyState
                    image={`${process.env.REACT_APP_CDN_URL}/assets/safe.svg`}
                    text={localization.assistant.notAutherized}
                  />
                </Flex>
              )}
              {!isAssistant && (
                <MixpanelConsumer>
                  {mixpanel => (
                    <Switch>
                      <Route
                        path="/"
                        exact
                        render={routeProps => (
                          <AssistantsContainer
                            {...routeProps}
                            localization={localization.assistant}
                            language={language}
                            mixpanel={mixpanel}
                            supportSingleLanguage={supportSingleLanguage}
                          />
                        )}
                      />
                      <Redirect to="/" />
                    </Switch>
                  )}
                </MixpanelConsumer>
              )}
            </EnterpriseLayout>
          </Router>
        </Provider>
      </FeatureFlagContainer.Provider>
    );
  }
}

const mapStateToProps = state => ({
  isUserLoaded: state.user.loaded,
  user: state.user.user,
});

export default withTranslation(connect(mapStateToProps)(App));
