import React from 'react';
import { language } from '@vezeeta/enterprise-store/lib/types/language';
// eslint-disable-next-line import/prefer-default-export
export const withTranslation = WrappedComponent =>
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        localization: undefined,
      };
    }
    componentDidMount() {
      fetch(`/localization/${language}/${language}.json`)
        .then(response => response.json())
        .then(localization => {
          this.setState({ localization });
        })
        .catch(() => {});
    }
    render() {
      const { localization } = this.state;
      return <WrappedComponent localization={localization} />;
    }
  };
